<template>
    <div :class="['choose-container', standalone ? '--standalone' : '', vertical ? '--vertical' : '']">
        <button 
            v-for="option in options"
            :key="option.value"
            :class="['btn', selectedValue == option.value ? 'selected' : '', size == 'small' ? 'btn-sm' : '']"
            type="button"
            @click="clickButton(option.value)"
        >
            <i :class="['fas', `${option.icon}`]"></i>&ensp;{{ option.label }}
        </button>
    </div>
</template>

<script setup>
const props = defineProps({
    context: Object
})

const options = props.context.options
const size = props.context.size || 'normal'
const standalone = props.context.standalone || false
const vertical = props.context.vertical || false
const selectedValue = ref('')
selectedValue.value = props.context.value

const clickButton = (value) => {
    props.context.node.input(value)
    selectedValue.value = value
}
</script>

<style lang="scss" scoped>
.choose-container{
    width: 100%;
    display: flex;

    &.--standalone{
        gap: 1em;
        .btn{
            flex-basis: initial;
            flex-grow: initial;
            flex-shrink: initial;
            border-radius: var(--border-radius);
            padding: .5em;
            display: grid;
            place-items: center;
            width: calc(15px + 1em + 4px);
            height: calc(15px + 1em + 4px);
            
            i{
                width: 15px;
                height: 15px;
            }
        }
    }
    @media only screen and (max-width: 650px) {
        &:not(.--standalone) {
            flex-direction: column;
            .btn{
                &:first-of-type{
                    border-radius: 0 !important;
                    border-top-left-radius: var(--border-radius) !important;
                    border-top-right-radius: var(--border-radius) !important;
                }
                &:last-of-type{
                    border-radius: 0 !important;
                    border-bottom-left-radius: var(--border-radius) !important;
                    border-bottom-right-radius: var(--border-radius) !important;
                }
            }
        }
    }

    &.--vertical{
        flex-direction: column;

        .btn{
            &:first-of-type{
                border-radius: 0;
                border-top-left-radius: var(--border-radius);
                border-top-right-radius: var(--border-radius);
            }
            &:last-of-type{
                border-radius: 0;
                border-bottom-left-radius: var(--border-radius);
                border-bottom-right-radius: var(--border-radius);
            }
        }
    }
    
    .btn{
        border-radius: 0px;
        padding: 1em 2em;
        flex-grow: 0;
        height: auto;
        flex-shrink: 0;
        flex-basis: 50%;
        transition: background-color .5s var(--cubic-animation), border-color .5s var(--cubic-animation), color .5s var(--cubic-animation);

        &:hover{
            box-shadow: none;
            border-color: var(--tertiary);
            color: var(--tertiary);
        }
    
        &:first-of-type{
            border-top-left-radius: var(--border-radius);
            border-bottom-left-radius: var(--border-radius);
        }
        &:last-of-type{
            border-top-right-radius: var(--border-radius);
            border-bottom-right-radius: var(--border-radius);
        }

        &.selected{
            background-color: var(--tertiary);
            border-color: var(--tertiary);
            color: white;
        }
    }
}
</style>