import { nl } from "@formkit/i18n"
import { createInput } from "@formkit/vue"
import type { DefaultConfigOptions } from "@formkit/vue"
import "@formkit/themes/genesis"
import { generateClasses } from "@formkit/themes"
import { createAutoAnimatePlugin, createFloatingLabelsPlugin, createMultiStepPlugin } from "@formkit/addons"
import ButtonChoise from "@/components/Inputs/ButtonChoise.vue"
import Address from "@/components/Inputs/Address.vue"

const config: DefaultConfigOptions = {
    locales: { nl },
    locale: 'nl',
    theme: "genesis",
    inputs: {
        button_chooser: createInput(ButtonChoise, {
            props: ['options', 'size', 'standalone', 'vertical']
        }),
        address: createInput(Address)
    },
    config: {
        classes: generateClasses({
            submit: {
                input: '$reset btn btn-success btn-fw'
            },
            button: {
                input: '$reset btn btn-tertiary btn-outline'
            }
        })
    },
    plugins: [
        createAutoAnimatePlugin(),
        createFloatingLabelsPlugin({
            useAsDefault: true
        }),
        createMultiStepPlugin()
    ]
}

export default config